const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "专家姓名",
                prop: "ZJXM",
                minWidth: "80"
            }, {
                label: "性别",
                prop: "ZJXB",
                minWidth: "70"
            }, {
                label: "所在单位",
                prop: "SZDW",
                minWidth: "200"
            }, {
                label: "职称/职位",
                prop: "ZWZC",
                minWidth: "80"
            }, {
                label: "研究方向",
                prop: "YJFX",
                minWidth: "180"
            }, {
                label: "研究方向类型",
                prop: "YJFXLX",
                minWidth: "160"
            }],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnEdit',
                    title: '编辑',
                    icon: 'el-icon-edit',
                    color: 'green'
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnDelete',
                    title: '删除',
                    icon: 'el-icon-delete',
                    color: 'red',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnCreateUser',
                    title: '创建账户',
                    icon: 'el-icon-user',
                    color: 'green',
                    showMethod: 'showCreateUserBtn',
                }]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        treeselectData: [{
            clearable: true,
            searchable: true,
            placeholder: '专家类型',
            class: 'selectRound',
            width: '200px',
            list: [],
            optionLabel: 'label',
            optionValue: 'id',
            value: 'YJFX',
            operateType: 'treesearch',
            isShow: true
        }],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建',
            round: true,
            backColor: '#28ccd9',
            // }, {
            //     btnType: 'button',
            //     operateType: 'buttonExport',
            //     name: '导出',
            //     round: true,
            //     backColor: '#ffcc66',
            //     color: '#fff'
        }]
    }
};
export { selfData };