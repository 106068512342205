<template>
    <div>
        <sn-table-group class="expert-information-grid" :tableGroupAttributes="tableGroupAttributes" :commonMethods="commonMethods" @handleChange="handleChange"></sn-table-group>
    </div>
</template>
<script>

import { commonData } from '../sn-table-group-common';
import { selfData } from './model/sn-table-group';
import commonMethodMixin from '../commonMethodMixin';
import { mapActions } from 'vuex';
import { commonMethods } from './model/table-methods';
export default {
    name: 'expert_information_grid',
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            commonMethods,
            infoPage: 'expert_information_form',
            // 筛选项
            keyword: '', // 关键字
            itemid: '8020513', // 业务编号
            tasktype: '',
        };
    },
    mounted() {
        this.getZjlxList();
    },
    methods: {
        ...mapActions([
            'GetResearchDirection', //获取专家的研究方向树结构
            'DelExpertInfo', //删除服务
            'GetExpertList', //获取列表服务
            'SaveToUser', //创建账户
        ]),
        // 获取专家类型列表
        async getZjlxList() {
            let result = await this.GetResearchDirection();
            if (result && result.length > 0) {
                result.forEach((item) => {
                    item.id = item.CODE;
                    item.label = item.NAME;
                    if (item.CHILDREN && item.CHILDREN.length>0) {
                        item.children = item.CHILDREN;
                        this.handleChild(item.children);
                    }
                });
                this.tableGroupAttributes.snSearch.treeselectData[0].list = result;
            }
        },
        handleChild(val) {
            if (val && val.length > 0) {
                val.forEach((ele) => {
                    ele.id = ele.CODE;
                    ele.label = ele.NAME;
                    if (ele.CHILDREN && ele.CHILDREN.length>0) {
                        ele.children = ele.CHILDREN;
                        this.handleChild(ele.children);
                    }
                });
                return val;
            }
        },
        async getTableData() {
            this.tableGroupAttributes.loading = true;
            let res = await this.GetExpertList({
                keyWord: this.keyword,	//查询关键字
                expertType: this.tasktype, //专家研究方向类型-小类
                pageSize: this.tableGroupAttributes.snPage.tiaoshu,	//每页最大记录数
                pageIndex: this.tableGroupAttributes.snPage.currentPage,	//页索引
            })
            this.tableGroupAttributes.snTable.table.data =  res.DATA || [];
            this.tableGroupAttributes.snPage.count = Number(res.COUNT) || 0;
            this.tableGroupAttributes.loading = false;
        },
        // 删除数据
        // eslint-disable-next-line no-unused-vars
        async delData(id, done, instance) {
            let res = await this.DelExpertInfo({
                id: id,
            });
            this.delOver(res, done);
        },
        async columnCreateUserClick(val){
            let result = await this.SaveToUser({
                zjid: val[0].ID,
            })
            if(result.ISSUCCESS){
                this.$message({
                    message: `账号：${result.RESULTVALUE.NAME}，密码：${result.RESULTVALUE.PASSWORD}`,
                    type: 'success'
                });
                this.getTableData();
            }else{
                this.$message({
                    message: '创建失败',
                    type: 'error'
                });
            }
        }
    },
};
</script>
<style lang="scss" scoped>

</style>