const commonMethods = {
    // 创建账户
    showCreateUserBtn(row) {
        // YHID：0未转为用户,1已转为用户
        if (Number(row.YHID) === 0) {
            return true;
        } else {
            return false;
        }
    },
};
export { commonMethods };